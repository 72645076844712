<template>
    <div>
        <div v-for="(action, index) in remainingActions">
            <template v-if="!action[1].fields.isEmpty()">
                <upload-products-form v-if="action[0] === 'add-products-via-csv'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="getColourType(action[0], index)"
                ></upload-products-form>
                <add-product-form v-else-if="action[0] === 'add-product'" :action="action[1]"
                                  :actionKey="action[0]"
                                  :redirectOnSuccess="true"
                                  :onSuccessPath="$router.currentRoute.fullPath"
                                  :showButton="true"
                                  additionalButtonClasses="my-2 w-full"
                                  :buttonType="getColourType(action[0], index)"
                ></add-product-form>
                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty()">
                <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties"
                                     :onSuccessPath="deletionPath && action[0].includes('delete') ? deletionPath :$router.currentRoute.fullPath"
                ></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import QuoteApprovalAction from "@/v2/components/QuoteApprovalAction";
import ReturnsForm from "@/v2/components/forms/ReturnsForm";
import GoodsInNoteForm from "@/v2/components/forms/GoodsInNoteForm";
import ReconcileOrderForm from "@/v2/components/forms/ReconcileOrderForm";
import NewCreditNoteForm from "@/v2/components/forms/NewCreditNoteForm";
import EmailPreviewForm from "@/v2/components/forms/EmailPreviewForm";
import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm";
import SplitOrderForm from "@/v2/components/forms/SplitOrderForm";
import AddProductForm from "@/v2/components/forms/AddProductForm.vue";
import UploadProductsForm from "@/components/CustomForms/UploadProductsForm.vue";
export default {
    name: "Actions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        UploadProductsForm, AddProductForm,
        QuoteApprovalAction,
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        ReturnsForm,
        GoodsInNoteForm,
        ReconcileOrderForm,
        NewCreditNoteForm,
        EmailPreviewForm,
        SelectQuoteForm,
        SplitOrderForm
    },
    props:{
        response:{
            type: Object
        },
        remainingActions:{
            type: Object
        },
        deletionPath:{
            type: String,
            default: null
        }
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        }
    }
}
</script>

<style scoped>

</style>
